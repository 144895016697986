import ObjectAdhoc from '@/ObjectAdhoc.vue'
import VueInstance from 'vue'
import i18n from '@/i18n'

export const install = function (Vue, options = {}) {
  console.info('Install map')
  VueInstance.prototype = Vue.prototype
  Vue.component('plkmap', ObjectAdhoc)
  if (options.translations) {
    options.translations.init(i18n, { overwrite: false })
  }
}
export default ObjectAdhoc
