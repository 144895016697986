<template>
  <div class="plkmap">
    <div class="map-container" :class="{'haveTimestamp': haveTimestamp}">
      <div v-if="loading" class="loading">Loading data</div>
      <div class="map" :id="idMap" :class="[idMap]"></div>
      <canvas class="deckgl-overlay" :id="idDeck"  :class="[idDeck]"></canvas>
    </div>

    <Slider class="slider" v-if="haveTimestamp && minFilterValue && maxFilterValue" :min="minFilterValue" :max="maxFilterValue" @onChangeValue="(values) => filterValues = values"></Slider>
  </div>
</template>

<script>
import Vue from 'vue'
import Slider from '@/Slider.vue'
import { PlakaStore } from '@plakastudio/plaka-api-js'
import mapboxgl from 'mapbox-gl'
import { Deck } from '@deck.gl/core'
import { DataFilterExtension } from '@deck.gl/extensions'
import { ScatterplotLayer } from '@deck.gl/layers'
import { HexagonLayer } from '@deck.gl/aggregation-layers'
import GL from '@luma.gl/constants'
import readme from '@/README.md'
import { v4 as uuidv4 } from 'uuid'
const { name, type, fields, icon } = require('../package.json')
export const TYPE = name
const CONCATENATION_KEY_SEPARATOR = '.'
console.info('Init Map file', window.deck)

export default {
  name: TYPE,
  components: {
    Slider
  },
  data () {
    return {
      filterValues: [undefined, undefined],
      idMap: `map-${uuidv4()}`,
      idDeck: `deck-${uuidv4()}`,
      mapVisible: true,
      loading: false,
      countries: {
        'Alemania': [51.165691, 10.451526],
        'Andorra': [42.546245, 1.601554],
        'Arabia Saudita': [23.885942, 45.079162],
        'Argelia': [28.033886, 1.659626],
        'Argentina': [-38.416097, -63.616672],
        'Armenia': [40.069099, 45.038189],
        'Australia': [-25.274398, 133.775136],
        'Bélgica': [50.503887, 4.469936],
        'Bolivia': [-16.290154, -63.588653],
        'Brasil': [-14.235004, -51.925280],
        'Cabo Verde': [16.002082, -24.013197],
        'Canadá': [56.130366, -106.346771],
        'Chile': [-35.675147, -71.542969],
        'Chipre': [35.126413, 33.429859],
        'Colombia': [4.570868, -74.297333],
        'Costa Rica': [9.748917, -83.753428],
        'Croacia': [45.100000, 15.200000],
        'Egipto': [26.820553, 30.802498],
        'El Salvador': [13.794185, -88.896530],
        'Emiratos Árabes Unidos': [23.424076, 53.847818],
        'España': [40.463667, -3.749220],
        'Estados Unidos de América': [37.090240, -95.712891],
        'Etiopía': [9.145000, 40.489673],
        'Francia': [46.227638, 2.213749],
        'Georgia': [42.315407, 43.356892],
        'Grecia': [39.074208, 21.824312],
        'Guatemala': [15.783471, -90.230759],
        'Honduras': [15.199999, -86.241905],
        'Hong Kong': [22.396428, 114.109497],
        'India': [20.593684, 78.962880],
        'Italia': [41.871940, 12.567380],
        'Japón': [36.204824, 138.252924],
        'Kenya': [-0.023559, 37.906193],
        'Kosovo': [42.602636, 20.902977],
        'Malasia': [4.210484, 101.975766],
        'Malta': [35.937496, 14.375416],
        'Marruecos': [31.791702, -7.092620],
        'México': [23.634501, -102.552784],
        'Nicaragua': [12.865416, -85.207229],
        'Nueva Zelanda': [-40.900557, 174.885971],
        'Omán': [21.512583, 55.923255],
        'Panamá': [8.537981, -80.782127],
        'Paraguay': [-23.442503, -58.443832],
        'Perú': [-9.189967, -75.015152],
        'Polonia': [51.919438, 19.145136],
        'Portugal': [39.399872, -8.224454],
        'Qatar': [25.354826, 51.183884],
        'Reino Unido': [55.378051, -3.435973],
        'República Dominicana': [18.735693, -70.162651],
        'Serbia': [44.016521, 21.005859],
        'Singapur': [1.352083, 103.819836],
        'Sri Lanka': [7.873054, 80.771797],
        'Sudáfrica': [-30.559482, 22.937506],
        'Tailandia': [15.870032, 100.992541],
        'Taiwán': [23.697810, 120.960515],
        'Trinidad y Tobago': [10.691803, -61.222503],
        'Túnez': [33.886917, 9.537499],
        'Turquía': [38.963745, 35.243322],
        'Ucrania': [48.379433, 31.165580],
        'Uruguay': [-32.522779, -55.765835],
        'Venezuela': [6.423750, -66.589730]
      },
      cube: undefined,
      mapLoaded: false,
      deckLoaded: false,
      deck: undefined,
      map: undefined,
      minFilterValue: undefined,
      maxFilterValue: undefined,
      config: {
        mapStyles: {
          mapboxLight: 'mapbox://styles/mapbox/light-v9',
          mapboxDark: 'mapbox://styles/mapbox/dark-v9'
        }
      }
    }
  },
  computed: {
    initialState () {
      return {
        ...{
          longitude: -4.962615,
          latitude: 39.2548537,
          bearing: 0,
          zoom: 6.0,
          pitch: 40.5,
          center: !this.paramsInitials.center || this.paramsInitials.center.length === 0 ? [-4.962615, 39.2548537] : this.paramsInitials.center
        },
        ...this.paramsInitials
      }
    },
    layers () {
      console.info('Generate layers')
      if (this.plkparams.layerType === 'Scatter') {
        return this.scatterLayer
      } else {
        return this.hexagonLayer
      }
    },
    scatterLayer () {
      const layers = []
      if (this.haveTimestamp && (!this.filterValues[0] || !this.filterValues[1])) {
        return layers
      }
      let layerConfig = {
        id: 'points-layer',
        data: this.geoPoints.data,
        getPosition: d => d.position,
        getFillColor: this.getFillColor,
        getRadius: this.getRadius,
        pickable: true,
        parameters: {
          // prevent flicker from z-fighting
          [GL.DEPTH_TEST]: false,

          // turn on additive blending to make them look more glowy
          [GL.BLEND]: true,
          [GL.BLEND_SRC_RGB]: GL.ONE,
          [GL.BLEND_DST_RGB]: GL.ONE,
          [GL.BLEND_EQUATION]: GL.FUNC_ADD
        }
      }
      if (this.haveTimestamp) {
        layerConfig = {
          ...layerConfig,
          ...{
            getFilterValue: (element) => element.timestamp,
            filterRange: this.filterValues,
            filterSoftRange: [
              this.filterValues[0] * 0.9 + this.filterValues[1] * 0.1,
              this.filterValues[0] * 0.1 + this.filterValues[1] * 0.9
            ],
            extensions: [
              new DataFilterExtension({
                filterSize: 1,
                // Enable for higher precision, e.g. 1 second granularity
                // See DataFilterExtension documentation for how to pick precision
                fp64: false
              })
            ]
          }
        }
      }
      layers.push(new ScatterplotLayer(layerConfig))
      return layers
    },
    hexagonLayer () {
      const layers = []
      if (this.haveTimestamp && (!this.filterValues[0] || !this.filterValues[1])) {
        return layers
      }
      let layerConfig = {
        id: 'points-layer',
        colorRange: [
          [1, 152, 189],
          [73, 227, 206],
          [216, 254, 181],
          [254, 237, 177],
          [254, 173, 84],
          [209, 55, 78]
        ],
        elevationRange: [0, 1000],
        elevationScale: 250,
        data: this.geoPoints.data,
        pickable: true,
        extruded: true,
        getRadius: 1000,
        getPosition: d => d.position
      }
      if (this.haveTimestamp) {
        layerConfig = {
          ...layerConfig,
          ...{
            getFilterValue: (element) => element.points[0].timestamp,
            filterRange: this.filterValues,
            filterSoftRange: [
              this.filterValues[0] * 0.9 + this.filterValues[1] * 0.1,
              this.filterValues[0] * 0.1 + this.filterValues[1] * 0.9
            ],
            extensions: [
              new DataFilterExtension({
                filterSize: 1,
                // Enable for higher precision, e.g. 1 second granularity
                // See DataFilterExtension documentation for how to pick precision
                fp64: false
              })
            ]
          }
        }
      }
      layers.push(new HexagonLayer(layerConfig))
      return layers
    },
    haveFilters () {
      return (this.connectionStarted ? PlakaStore.Store.getters[PlakaStore.PLAKA.GETTERS.GET_FILTERS](this.country.map(e => e.idElement)) : []).length > 0
    },
    paramsInitials () {
      return this.plkparams.initialstate ? this.plkparams.initialstate : {}
    },
    dimensions () {
      return this.timestamps.concat(this.plkparams.dimensions || [])
    },
    country () {
      return this.plkparams.country ? [this.plkparams.country] : []
    },
    timestamps () {
      return this.plkparams.timestamp ? [this.plkparams.timestamp] : []
    },
    coordinates () {
      return this.plkparams.latitude && this.plkparams.longitude ? [this.plkparams.latitude, this.plkparams.longitude] : []
    },
    measure () {
      return this.plkparams.measure ? [this.plkparams.measure] : []
    },
    haveMeasure () {
      return this.measure.length
    },
    haveCountry () {
      return this.country.length > 0
    },
    haveTimestamp () {
      return this.timestamps.length > 0
    },
    haveCoordinates () {
      return this.coordinates.length === 2
    },
    elements () {
      return this.haveCoordinates ? this.coordinates.concat(this.dimensions).concat(this.measure) : this.haveCountry ? this.country.concat(this.measure) : []
    },
    conditions () {
      return this.plkparams.conditions || []
    },
    geoPoints () {
      let geo = []
      let minTimeRange
      let maxTimeRange
      if (this.cube && this.haveCoordinates) {
        const start = Date.now()
        const latitudes = this.cube.getDimensionValues(this.plkparams.latitude.idElement)
        const longitudes = this.cube.getDimensionValues(this.plkparams.longitude.idElement)
        const timestamps = this.haveTimestamp ? this.cube.getDimensionValues(this.plkparams.timestamp.idElement) : undefined
        const values = Object.keys(this.cube.getValues())
        const extraDimension = this.dimensions.map(dimension => this.cube.getDimensionValues(dimension.idElement))

        for (let valueKey of values) {
          const [latIndex, lonIndex, ...dimensionsKey] = valueKey.split(CONCATENATION_KEY_SEPARATOR)
          const extraData = this.dimensions.map((d, index) => extraDimension[index][dimensionsKey[index]])
          let timestamp
          if (this.haveTimestamp) {
            timestamp = Number(timestamps[dimensionsKey[0]].id)
            minTimeRange = Math.min(minTimeRange || timestamp, timestamp)
            maxTimeRange = Math.max(maxTimeRange || timestamp, timestamp)
          }
          geo.push(this.generateRowObject({ id: valueKey, lng: longitudes[lonIndex].id, lat: latitudes[latIndex].id, timestamp, value: values[valueKey], extraData }))
        }
        const end = Date.now()
        console.log(`Execution time: ${end - start} ms`)
      } else if (this.cube && this.haveCountry) {
        this.cube.getDimensionValues(this.plkparams.country.idElement).forEach((e, index) => {
          const geoCountry = this.countries[e.id]
          if (geoCountry) {
            geo.push(this.generateRowObject({ id: e.id, lng: geoCountry[0], lat: geoCountry[0], value: this.cube.getValues()[`${index}.0`] }))
          }
        })
      }
      return { data: geo, minTimeRange, maxTimeRange }
    },
    getFillColor () {
      if (!this.plkparams.getFillColor) return (d) => [255, 255, 0]
      let fun = (new Function(['element'], this.plkparams.getFillColor))
      return (d) => fun.apply(this, [d])
    },
    getRadius () {
      if (!this.plkparams.getRadius) return (d) => 500
      let fun = (new Function(['element'], this.plkparams.getRadius))
      return (d) => fun.apply(this, [d])
    },
    tooltip () {
      if (!this.plkparams.tooltip) {
        return (object) => ({
          html: `<div>${object.value}</div>`,
          style: {
            backgroundColor: '#fff',
            fontSize: '0.1em'
          }
        })
      }
      let fun = (new Function(['object'], this.plkparams.tooltip))
      return (object) => fun.apply(this, [object])
    }
  },
  methods: {
    updateLayers () {
      this.deck.setProps({
        layers: this.layers
      })
    },
    generateRowObject ({ id, lng, lat, value, extraData, timestamp }) {
      return { id, position: [Number(lng), Number(lat)], value, extraData, timestamp }
    },
    onInitComponent () {
      // configure MapBox to use the access token
      mapboxgl.accessToken = this.plkparams.mapboxApiAccessToken
      this.init()
    },
    init () {
      // set up the map
      this.map = new mapboxgl.Map({
        container: this.$el.getElementsByClassName(this.idMap)[0],
        style: this.config.mapStyles.mapboxDark,
        interactive: false, // deck.gl will handle map interaction
        ...this.initialState,
        center: this.initialState.center
        // preserveDrawingBuffer: true, // enable this if recording
      })
      this.map.on('load', () => {
        this.mapLoaded = true
      })
      // set up deckgl
      const _self = this
      this.deck = new Deck({
        useDevicePixels: true,
        canvas: _self.$el.getElementsByClassName(_self.idDeck)[0],
        glOptions: {
          preserveDrawingBuffer: true // enable this if recording
        },
        initialViewState: this.initialState,
        controller: true, // deck.gl handles map interaction
        onViewStateChange ({ viewState }) {
          // keep the map in sync with deckGL's view
          _self.map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            bearing: viewState.bearing,
            pitch: viewState.pitch
          })
        },
        onLoad () {
          this.deckLoaded = true
        },
        layers: [],
        getTooltip: ({ object }) => object && this.tooltip(object)
      })
    },
    getPackage () {
      return { name, type, fields, icon }
    },
    getReadme () {
      return readme
    },
    startData () {
      if (this.getConnection() && (this.haveCountry || this.haveCoordinates) && this.haveMeasure) {
        this.getConnection().initData(this.elements, this.conditions, { onInit: this.onInitLoad, onChange: this.onChangeData })
      } else {
        console.warn(`Necesary element type '${this.getConnection().ELEMENT_TYPES.DIMENSION}' (country or latitude / longitude) and '${this.getConnection().ELEMENT_TYPES.MEASURE}' to create plkmap`)
      }
    },
    onInitLoad () {
      Vue.set(this, 'loading', true)
    },
    onChangeData (cube) {
      Vue.set(this, 'cube', cube)
      Vue.set(this, 'loading', false)
    }
  },
  watch: {
    geoPoints () {
      if (this.geoPoints.minTimeRange) this.minFilterValue = this.geoPoints.minTimeRange
      if (this.geoPoints.maxTimeRange) this.maxFilterValue = this.geoPoints.maxTimeRange
    },
    layers (newLayer, oldLayer) {
      if ((oldLayer.length !== 0 && newLayer.length !== 0) || oldLayer.length !== newLayer.length) {
        this.updateLayers(this.layers)
      }
    },
    connectionStarted () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        this.startData()
      }
    }
  }
}
</script>

<style scoped>
  .plkmap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .slider {
    position: absolute;
    bottom: 10px;
  }
  .map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .map {
    width: 100%;
    height: 100%;
  }

  .deckgl-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .loading {
    text-align: center;
    font-size: xx-small;
    color: white;
    background: #343332;
  }

  >>>.mapboxgl-control-container {
    display: none;
  }

</style>
