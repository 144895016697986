<template>
  <div class="min-max-slider" :data-legendnum="legendnum">
    <label for="min">Minimum price</label>
    <input :title="minSelectedValue" id="min" class="min" name="min" type="range" :step="step" :min="min" :max="max" />
    <label for="max">Maximum price</label>
    <input :title="maxSelectedValue" id="max" class="max" name="max" type="range" :step="step" :min="min" :max="max" />
  </div>
</template>
<script>
const thumbsize = 14

export default {
  name: 'Slider',
  props: {
    min: {
      required: true
    },
    max: {
      required: true
    },
    step: {
      required: false,
      default: 86400000
    },
    legendnum: {
      required: false,
      default: 2
    }
  },
  data () {
    return {
      minSelected: 0,
      maxSelected: 0
    }
  },
  methods: {
    onInitComponent () {
      this.init(this.$el)
    },
    draw (slider, splitvalue) {
      /* set function consts */
      const min = slider.querySelector('.min')
      const max = slider.querySelector('.max')
      const lower = slider.querySelector('.lower')
      const upper = slider.querySelector('.upper')
      const legend = slider.querySelector('.legend')
      const thumbsize = parseInt(slider.getAttribute('data-thumbsize'))
      const rangewidth = parseInt(slider.getAttribute('data-rangewidth'))
      const rangemin = parseInt(slider.getAttribute('data-rangemin'))
      const rangemax = parseInt(slider.getAttribute('data-rangemax'))

      /* set min and max attributes */
      min.setAttribute('max', splitvalue)
      max.setAttribute('min', splitvalue)

      /* set css */
      min.style.width = `${parseInt(thumbsize + ((splitvalue - rangemin) / (rangemax - rangemin)) * (rangewidth - (2 * thumbsize)))}px`
      max.style.width = `${parseInt(thumbsize + ((rangemax - splitvalue) / (rangemax - rangemin)) * (rangewidth - (2 * thumbsize)))}px`
      min.style.left = '0px'
      max.style.left = `${parseInt(min.style.width)}px`
      min.style.top = `${lower.offsetHeight}px`
      max.style.top = `${lower.offsetHeight}px`
      legend.style.marginTop = `${min.offsetHeight}px`
      slider.style.height = `${(lower.offsetHeight + min.offsetHeight + legend.offsetHeight)}px`

      /* correct for 1 off at the end */
      if (max.value > (rangemax - 1)) max.setAttribute('data-value', rangemax)

      /* write value and labels */
      max.value = max.getAttribute('data-value')
      min.value = min.getAttribute('data-value')
      lower.innerHTML = '' // this.getLabel(min.getAttribute('data-value'))
      upper.innerHTML = '' // this.getLabel(max.getAttribute('data-value'))
      this.minSelected = Number(min.getAttribute('data-value'))
      this.maxSelected = Number(max.getAttribute('data-value'))
    },
    getLabel (timestamp) {
      const date = new Date(Number(timestamp))
      return `${date.getMonth() + 1}/${date.getFullYear()}`
    },
    init (slider) {
      /* set function consts */
      const min = slider.querySelector('.min')
      const max = slider.querySelector('.max')
      const rangemin = parseInt(min.getAttribute('min'))
      const rangemax = parseInt(max.getAttribute('max'))
      const avgvalue = (rangemin + rangemax) / 2
      const legendnum = slider.getAttribute('data-legendnum')

      /* set data-values */
      min.setAttribute('data-value', rangemin)
      max.setAttribute('data-value', rangemax)

      /* set data consts */
      slider.setAttribute('data-rangemin', rangemin)
      slider.setAttribute('data-rangemax', rangemax)
      slider.setAttribute('data-thumbsize', thumbsize)
      slider.setAttribute('data-rangewidth', slider.offsetWidth)

      /* write labels */
      const lower = document.createElement('span')
      const upper = document.createElement('span')
      lower.classList.add('lower', 'value')
      upper.classList.add('upper', 'value')
      lower.appendChild(document.createTextNode(rangemin))
      upper.appendChild(document.createTextNode(rangemax))
      slider.insertBefore(lower, min.previousElementSibling)
      slider.insertBefore(upper, min.previousElementSibling)

      /* write legend */
      const legend = document.createElement('div')
      legend.classList.add('legend')
      const legendvalues = []
      for (let i = 0; i < legendnum; i++) {
        legendvalues[i] = document.createElement('div')
        const val = this.getLabel(Math.round(rangemin + (i / (legendnum - 1)) * (rangemax - rangemin)))
        legendvalues[i].appendChild(document.createTextNode(val))
        legend.appendChild(legendvalues[i])
      }
      slider.appendChild(legend)
      /* draw */
      this.draw(slider, avgvalue)

      /* events */
      min.addEventListener('input', () => this.update(min))
      max.addEventListener('input', () => this.update(max))
      this.$emit('onChangeValue', [this.minSelected, this.maxSelected])
    },
    update (el) {
      /* set function consts */
      const slider = el.parentElement
      const min = slider.querySelector('#min')
      const max = slider.querySelector('#max')
      const minvalue = Math.floor(min.value)
      const maxvalue = Math.floor(max.value)

      /* set inactive values before draw */
      min.setAttribute('data-value', minvalue)
      max.setAttribute('data-value', maxvalue)

      const avgvalue = (minvalue + maxvalue) / 2

      /* draw */
      this.draw(slider, avgvalue)
    }
  },
  computed: {
    minSelectedValue () {
      return this.getLabel(this.minSelected)
    },
    maxSelectedValue () {
      return this.getLabel(this.maxSelected)
    }
  },
  watch: {
    maxSelected () {
      this.$emit('onChangeValue', [this.minSelected, this.maxSelected])
    }
  }
}
</script>

<style>
  /* Input Slider css code */
  .min-max-slider {
    position: relative;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
    color: white;
  }
  .min-max-slider > label {
    display: none;
  }
  .min-max-slider span.value {
    height: 1.7em;
    font-weight: bold;
    /* display: inline-block; */
    display: none;
  }
  .min-max-slider span.value.lower::before {
    display: inline-block;
  }
  .min-max-slider span.value.upper::before {
    content: '-';
    display: inline-block;
    padding: .5rem;
  }
  .min-max-slider > .legend {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
  .min-max-slider > .legend > * {
    font-size: small;
    opacity: 0.25;
  }
  .min-max-slider > input {
    cursor: pointer;
    position: absolute;
  }
  /* webkit specific styling */
  .min-max-slider > input {
    -webkit-appearance: none;
    outline: none!important;
    background: transparent;
    background-image: linear-gradient(to bottom, transparent 0%, transparent 30%, silver 30%, silver 60%, transparent 60%, transparent 100%);
  }
  .min-max-slider > input::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 14px;
    /* Set a specific slider handle width */
    height: 14px;
    /* Slider handle height */
    background: #eee;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border: 1px solid gray;
    border-radius: 100%;
  }
  .min-max-slider > input::-webkit-slider-runnable-track {
    cursor: pointer;
  }

  /* End Input Slider css code */

</style>
